var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "associated_customer" }, [
    _c(
      "div",
      { staticClass: "associated_customer_up" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            model: {
              value: _vm.activeTabPaneName,
              callback: function ($$v) {
                _vm.activeTabPaneName = $$v
              },
              expression: "activeTabPaneName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "当前组织", name: "associatedCustomerOne" } },
              [
                _c("associatedPositonOne", {
                  ref: "one",
                  attrs: { rowItem: _vm.formConfig },
                  on: { refresh: _vm.refresh },
                }),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: "当前组织及下级组织",
                  name: "associatedCustomerTwo",
                },
              },
              [
                _c("associatedPositonTwo", {
                  ref: "two",
                  attrs: { rowItem: _vm.formConfig },
                  on: { refresh: _vm.refresh },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "danger", size: "small", icon: "el-icon-close" },
            on: { click: _vm.close },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }