<template>
  <div class="container">
    <div class="grid-content">
      <el-input placeholder="请输入搜索的组织" v-model="filterText"> </el-input>
      <el-tree
        v-if="showTree"
        class="filter-tree"
        :data="treeData"
        :props="defaultProps"
        highlight-current
        :default-expand-all="defaultExpandAll"
        :expand-on-click-node="false"
        ref="treeRef"
        @node-click="handleCheckChange"
      >
      </el-tree>
    </div>
    <div class="dialog-footer">
        <el-button
          @click="$emit('submit',codes)"
          type="primary"
          size="small"
          icon="el-icon-check"
          >确定
        </el-button>
        <el-button
          @click="$emit('onClose')"
          type="danger"
          size="small"
          icon="el-icon-close"
          >关闭
        </el-button>
      </div>
  </div>
</template>

<script>
import request from '../../../../../../../utils/request';

export default {
  props: {
    value: String,
    restful: String,
    title: String,
    modalConfig: Object,
    defaultProps: {
      type: Object,
      default() {
        return {
          children: 'children',
          label: 'name',
          value: 'code',
        };
      },
    },
  },
  watch: {
    filterText(v) {
      if (v) {
        this.defaultExpandAll = true;
      } else {
        this.defaultExpandAll = false;
      }
      this.showTree = false;
      this.getTreeList();
    },
  },
  data() {
    return {
      filterText: '',
      treeData: [],
      codes: '',
      defaultExpandAll: false,
      showTree: true,
    };
  },
  created() {
    this.getTreeList();
  },
  methods: {
    getTreeList() {
      const data = {
        name: this.filterText,
        enableStatus: '009',
        excludePositionChildrenOrgCodeList: (this.modalConfig.selectRow && this.modalConfig.selectRow.map((item) => item.positionCode)) || [],
      };
      request.post('/mdm/baseTreeController/orgTree', data, { headers: { functionCode: 'select-org' } }).then((res) => {
        if (res.success) {
          this.treeData = res.result;
          this.showTree = true;
        }
      });
    },
    handleCheckChange(data) {
      this.codes = data[this.defaultProps.value];
      console.log(91111, this.codes);
      this.$emit('change', this.codes);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  .tittle {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    padding-bottom: 10px;
  }
  .filter-tree {
    padding-top: 10px;
  }
  .dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
}

</style>
