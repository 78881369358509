<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import { Form, PositionTable } from '../form';
import Modal from '../../../../../components/modal';
import Associat from '../associated/position_maintenance';
// import customerMaintenance from '../associated/customer_maintenance/customer_maintenance.vue';
import customerMaintenance from '../associated/customer_maintenance/customer_maintenance_out.vue';
import administrativeAreaMaintenance from '../associated/administrative_area_maintenance/administrative_area_maintenance.vue';
// import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    PositionTable,
    Associat,
    customerMaintenance,
    administrativeAreaMaintenance,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmOrgController/pageList',
      params: {
        code: '',
        // enableStatus: '009',
      },
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      console.log(val);
      this.params.code = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('mdm_organization');
    console.log(this);
  },
  methods: {
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },

    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },

    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formName = 'Form';
      console.log(val);
      // const urls = '';
      // const text = '';
      if (val.code === 'add') {
        this.formConfig = {};
        this.modalConfig.title = '新增';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.formConfig.code = 'add';
        this.openModal();
      }
      if (val.code === 'edit') {
        this.formConfig = {
          ...this.formConfig,
          ...val,
          ...row,
        };
        this.formConfig.code = 'edit';
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.openModal();
      } else if (val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          ...val,
          ...row,
        };
        this.formConfig.code = 'view';
        this.modalConfig.title = '查看';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.openModal();
      } else if (val.code === 'position_maintenance') {
        this.formName = 'Associat';
        this.modalConfig.title = '职位维护';
        this.formConfig = row;
        this.openModal();
      } else if (val.code === 'customer_maintenance') {
        this.formName = 'customerMaintenance';
        this.propsObjInData = { ...row, btnCode: 'customer_maintenance' };
        this.modalConfig.title = '客户维护';
        this.openModal();
      } else if (val.code === 'terminal_maintenance') {
        this.formName = 'customerMaintenance';
        this.propsObjInData = { ...row, btnCode: 'terminal_maintenance' };
        this.modalConfig.title = '终端维护';
        this.openModal();
      } else if (val.code === 'administrative_division') {
        this.formName = 'administrativeAreaMaintenance';
        this.propsObjInData = { ...row, btnCode: 'area_maintenance' };
        this.modalConfig.title = '行政区域维护';
        this.openModal();
      } else {
        // this.openModal();
      }
    },
    // disableClick({ val }, urls, text) {
    //   request.post(urls, [val.id]).then((res) => {
    //     if (res.success) {
    //       this.$message({
    //         type: 'success',
    //         message: `${text}成功`,
    //       });
    //       this.getList();
    //     }
    //   });
    // },
    // // 删除操作
    // deleteClick({ row }) {
    //   const ids = row ? [row.id] : this.selection;
    //   if (this.selection.length === 0) {
    //     this.$message('请选择一条数据');
    //     return false;
    //   }
    //   const title = '此操作将永久删除此数据, 是否继续?';
    //   const url = '/mdm/mdmOrgController/delete';
    //   const msg = '删除';

    //   const data = {
    //     params: ids,
    //     url,
    //     title,
    //     msg,
    //   };
    //   this.messageBox(data);
    //   return { row };
    // },
    // getConfigList() {
    //   request.post('/mdm/mdmtableconfig/query', { functionCode: 'mdm_organization' }).then((res) => {
    //     if (res.success) {
    //       this.configs.columns = this.configs.columns.concat(res.result.column);
    //       this.setButton(res.result.buttonVos);
    //     }
    //   });
    // },
  },
};
</script>
