<template>
  <div class="associated_customer">
    <div class="associated_customer_up">
      <el-tabs v-model="activeTabPaneName" type="card">
        <el-tab-pane label="当前组织" name="associatedCustomerOne">
          <associatedPositonOne
            :rowItem="formConfig"
            ref="one"
            @refresh="refresh"
          ></associatedPositonOne>
        </el-tab-pane>
        <el-tab-pane label="当前组织及下级组织" name="associatedCustomerTwo">
          <associatedPositonTwo
            :rowItem="formConfig"
            ref="two"
            @refresh="refresh"
          ></associatedPositonTwo>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="dialog-footer">
      <el-button @click="close" type="danger" size="small" icon="el-icon-close">关闭</el-button>
    </div>
  </div>
</template>

<script>
import Form from '../../../../../../components/form';
import associatedPositonOne from './associated_position_one.vue';
import associatedPositonTwo from './associated_position_two.vue';

export default {
  extends: Form,
  components: {
    associatedPositonOne,
    associatedPositonTwo,
  },
  data() {
    return {
      activeTabPaneName: 'associatedCustomerOne',
      active: 'associatedCustomerThree',
    };
  },
  methods: {
    // 关闭弹框
    close() {
      this.$emit('onClose');
    },
    // 联动刷新列表
    refresh() {
      this.$refs.one.getList();
      this.$refs.two.getList();
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.associated_customer_line {
  height: 2px;
  width: 100%;
  background-color: #aaa;
  margin: 30px 0;
}

.footer-btn {
  position: absolute;
  bottom: 0;
  right: 10px;

  button {
    width: 100px;
  }
}
</style>
