<script>
/** *
   * 客户维护  与 终端维护在同一个组件里面共用
   * yc
   */

import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';
import Org from '../position_maintenance/components';

export default {
  extends: TablePage,
  props: {
    propsObj: {},
  },
  components: {
    Org,
  },
  data() {
    return {
      params: {
        orgCode: this.propsObj.orgCode,
      },
      isCalculateHeight: false,
      configs: {
        toolBarConfig: [
          {
            name: '更换组织',
            buttonType: '0',
            buttonCode: 'changeOrg',
          },
          // {
          //   name: '导出',
          //   buttonType: '0',
          //   buttonCode: 'export',
          // },
        ],
        formConfig: {
          item: [
            // {
            //   field: 'positionLevelCode',
            //   search: 'true',
            //   title: '职位级别',
            //   type: 'select',
            //   apiUrl: '/mdm/mdmPositionLevelController/selectList',
            //   optionsKey: {
            //     label: 'positionLevelName',
            //     value: 'positionLevelCode',
            //   },
            //   paramsFixed: {
            //     enableStatus: '009',
            //   },
            // },
          ],
        },
      },
    };
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    // 重新 覆盖 按钮事件
    buttonClick({ val }) {
      if (val.buttonCode === 'changeOrg') {
        if (this.selectRow.length > 0) {
          this.modalConfig.title = '更换组织';
          this.formName = 'Org';
          this.modalClick({ val }, []);
        } else {
          this.$message.error('请选择数据');
        }
      }
    },
    onSubmit(codes) {
      if (!codes) {
        this.$message('请点击选择');
        return;
      }
      let apiUrl = '';
      let params = {};
      // 客户维护
      if (this.propsObj.btnCode === 'customer_maintenance') {
        apiUrl = '/mdm/mdmOrgRelationCustomerController/batchUpdateCustomerOrg';
        params = {
          orgCode: codes,
          originOrgCode: this.propsObj.orgCode,
          customerCodeList: this.selectRow.map((v) => v.customerCode),
        };
      } else if (this.propsObj.btnCode === 'terminal_maintenance') {
        // 终端维护
        apiUrl = '/mdm/mdmOrgRelationTerminalController/batchUpdateTerminalOrg';
        params = {
          orgCode: codes,
          originOrgCode: this.propsObj.orgCode,
          terminalCodeList: this.selectRow.map((v) => v.terminalCode),
        };
      }
      request.post(apiUrl, params).then((res) => {
        if (res.success) {
          this.closeModal();
          this.getList();
        }
        this.$message.success(res.message);
      });
    },
  },
  created() {
    if (this.propsObj.btnCode === 'customer_maintenance') {
      this.getConfigList('organization_managemen_customer_maintenancet', true, true);
    } else if (this.propsObj.btnCode === 'terminal_maintenance') {
      this.getConfigList('organization_managemen_terminal_maintenance', true, true);
    }
  },
  mounted() {},
};
</script>
