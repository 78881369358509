<template>
  <div class="associated_customer">
    <customerMaintenance :propsObj="propsObj"></customerMaintenance>

    <div class="dialog-footer">
      <el-button @click="close" type="danger" size="small" icon="el-icon-close">关闭</el-button>
    </div>
  </div>
</template>

<script>
import customerMaintenance from './customer_maintenance.vue';

export default {
  components: {
    customerMaintenance,

  },
  props: {
    propsObj: {},
  },
  data() {
    return {

    };
  },
  methods: {
    // 关闭弹框
    close() {
      this.$emit('onClose');
    },

  },
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">

  .footer-btn {
    position: absolute;
    bottom: 0;
    right: 10px;

    button {
      width: 100px;
    }
  }
</style>
