<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';
import Org from './components';

export default {
  extends: TablePage,
  props: {
    rowItem: {},
  },
  components: {
    Org,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmPositionController/findPositionUserOrgPageList',
      params: {
        thisOrgCode: this.rowItem.orgCode,
      },
      isCalculateHeight: false,
      configs: {
        toolBarConfig: [
          {
            name: '更换组织',
            buttonType: '0',
            buttonCode: 'changeOrg',
          },
          {
            name: '导出',
            buttonType: '0',
            buttonCode: 'export',
          },
        ],
        formConfig: {
          item: [
            {
              field: 'positionLevelCode',
              search: 'true',
              title: '职位级别',
              type: 'select',
              apiUrl: '/mdm/mdmPositionLevelController/selectList',
              optionsKey: {
                label: 'positionLevelName',
                value: 'positionLevelCode',
              },
              isSearch: true,
              paramsName: 'positionLevelName',
              paramsFixed: {
                enableStatus: '009',
              },
            },
          ],
        },
        tableConfig: {
          border: true,
          treeConfig: {
            children: 'children',
          },
          idKey: 'id', // 默认勾选判断字段
          resizable: true, // 所有的列是否允许拖动列宽调整大小
          showOverflow: true, // 当内容过长时显示为省略号
          keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
          highlightHoverRow: true, // 鼠标滑动高亮
        }, // 表信息
      },
    };
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    // 重新 覆盖 按钮事件
    buttonClick({ val }) {
      if (val.buttonCode === 'changeOrg') {
        if (this.selectRow.length > 0) {
          this.modalConfig.title = '更换组织';
          this.modalConfig.selectRow = this.selectRow;
          this.formName = 'Org';
          this.modalClick({ val }, []);
        } else {
          this.$message.error('请选择数据');
        }
      }
    },
    onSubmit(codes) {
      // 批量修改与职位关联的组织编码
      // /mdm/mdmPositionController/batchUpdatePositionOrg
      console.log(71111, codes);

      if (!codes) {
        this.$message('请点击选择');
        return;
      }
      const params = {
        orgCode: codes,
        positionCodeList: this.selectRow.map((v) => v.positionCode),
      };
      request.post('/mdm/mdmPositionController/batchUpdatePositionOrg', params).then((res) => {
        if (res.success) {
          this.closeModal();
          this.$emit('refresh');
        }
        this.$message.success(res.message);
      });
    },
  },
  created() {

  },
  mounted() {
    setTimeout(() => {
      this.getConfigList('current_organization_1', true, true);
    }, 200);
  },
};
</script>
