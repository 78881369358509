<script>
import tablePageTreeBase from '../../../../components/table_page_tree_base';
import TablePage from './table';

export default {
  extends: tablePageTreeBase,
  name: 'OrganizationManagement',
  components: {
    TablePage,
  },
  data() {
    return {
      placeholder: '请输入搜索的组织架构',
      title: '组织架构',
      restful: '/mdm/baseLazyTreeController/orgLazyTree',
      defaultProps: {
        enableStatus: '009',
      },
    };
  },
};
</script>
