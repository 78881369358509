<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import TableForm from './components';

formCreate.component('TableForm', TableForm);

export default {
  extends: Form,
  components: {
    TableForm,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
    };
  },
  async created() {
    await this.getFormRule('organization_add');

    // 设置默认值
    this.setValue({ enableStatus: '009' });
    const parentName = this.getRule('parentCode');
    parentName.restful = '/mdm/baseTreeController/orgTree';
    parentName.restfulParams = { enableStatus: '009' };
    parentName.headers = { functionCode: 'select-org-up' };
    parentName.props = {
      ...parentName.props,
      options: [],
      props: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
    };
    if (this.formConfig.code === 'view') {
      const suffix = [
        {
          type: 'input',
          field: 'createName',
          title: '创建人',
          props: {
            disabled: true,
          },
          col: {
            md: {
              span: 12,
            },
          },
        },
        {
          type: 'input',
          field: 'createDate',
          title: '创建时间',
          props: {
            disabled: true,
          },
          col: {
            md: {
              span: 12,
            },
          },
        },
      ];
      this.rule = this.rule.concat(suffix);
    }
    if (this.formConfig.id) {
      const data = {
        id: this.formConfig.id,
      };
      request.get('/mdm/mdmOrgController/detail', data).then((res) => {
        const { result } = res;
        result.createDate = `${result.createDate} ${result.createDateSecond}`;
        parentName.restfulParams = { enableStatus: '009', notUnderThisOrgCode: res.result.orgCode, excludeCodeAndChildren: res.result.orgCode };
        // this.reload();
        this.setValue(result);
      });
    }
  },
  mounted() {},
  methods: {
    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmOrgExtController/save';
        let params = formData;

        if (this.formConfig && this.formConfig.code === 'edit') {
          url = '/mdm/mdmOrgExtController/update';
          params = Object.assign(this.formConfig, formData);
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            console.log(this, '打印this');
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
      console.log(formData);
    },
  },
};
</script>
