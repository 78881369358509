<template>
  <div class="administrative_area_maintenance">
    <div class="administrative_area_maintenance_box">
      <div class="add-line">
        <div class="add-line-one">
          <el-button type="text" icon="el-icon-plus" @click="addAraItemLine">新增一行</el-button>
        </div>
      </div>
      <div class="form-table-box">
        <el-form ref="areaObjArrForm"  :model="areaObj"  label-position="left" label-width="0px">
          <div class="table-title">
            <div v-if="selectShowObj.countryShow === 'Y'" class="table-title-one">国家</div>
            <div class="table-title-two">省</div>
            <div class="table-title-three">市</div>
            <div class="table-title-four">区/县</div>
            <div v-if="selectShowObj.townshipShow ==='Y'" class="table-title-five">乡/镇</div>
            <div class="table-title-six">操作</div>
          </div>

          <div class="table-content" v-for="(item,index) in areaObj.areaObjArr" :key="index">
            <div v-if="selectShowObj.countryShow === 'Y'"  class="table-content-one">
              <el-form-item
                :prop="'areaObjArr.' + index + '.countryCode'"
                :rules="{required: true, message: '请选择', trigger: 'blur'}"
                label="">
                <el-select
                  @focus="focusSetOptionArr('',index,'countryCodeOptionsArr',item)"
                  @change="()=>{item.provinceCode = '';item.cityCode = '';item.districtCode = '';item.townshipCode = ''}"
                  v-model="item.countryCode"
                  filterable
                  placeholder="请选择">
                  <el-option v-for="(item2,index2) in item.countryCodeOptionsArr" :key="index2" :label="item2.regionName" :value="item2.regionCode"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="table-content-two">
              <el-form-item
                :prop="'areaObjArr.' + index + '.provinceCode'"
                label="">
                <el-select
                  @focus="focusSetOptionArr(item.countryCode,index,'provinceCodeOptionsArr',item)"
                  @change="()=>{item.cityCode = '';item.districtCode = '';item.townshipCode = ''}"
                  v-model="item.provinceCode"
                  filterable
                  clearable
                  placeholder="请选择">
                  <el-option v-for="(item2,index2) in item.provinceCodeOptionsArr" :key="index2" :label="item2.regionName" :value="item2.regionCode"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="table-content-three">
              <el-form-item label="">
                <el-select
                  @focus="focusSetOptionArr(item.provinceCode,index,'cityCodeOptionsArr',item)"
                  @change="()=>{item.districtCode = '';item.townshipCode = ''}"
                  v-model="item.cityCode"
                  clearable
                  filterable
                  placeholder="请选择">
                  <el-option v-for="(item2,index2) in item.cityCodeOptionsArr" :key="index2" :label="item2.regionName" :value="item2.regionCode"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="table-content-four">
              <el-form-item label="">
                <el-select
                  clearable
                  @focus="focusSetOptionArr(item.cityCode,index,'districtCodeOptionsArr',item)"
                  @change="()=>{item.townshipCode = ''}"
                  v-model="item.districtCode"
                  filterable
                  placeholder="请选择">
                  <el-option v-for="(item2,index2) in item.districtCodeOptionsArr" :key="index2" :label="item2.regionName" :value="item2.regionCode"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-if="selectShowObj.townshipShow ==='Y'" class="table-content-five">
              <el-form-item label="">
                <el-select
                  clearable
                  @focus="focusSetOptionArr(item.districtCode,index,'townshipCodeOptionsArr',item)"
                  v-model="item.townshipCode"
                  filterable
                  placeholder="请选择">
                  <el-option v-for="(item2,index2) in item.townshipCodeOptionsArr" :key="index2" :label="item2.regionName" :value="item2.regionCode"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="table-content-six">
              <el-form-item label="">
                <el-button type="danger" icon="el-icon-delete" circle @click="delAreaArrItem(areaObj.areaObjArr,index)"></el-button>

              </el-form-item>
            </div>
          </div>

        </el-form>

      </div>

     <div class="dialog-footer">
         <el-button
          type="primary"
          @click="submitAreaObjArrForm"
          size="small"
          icon="el-icon-check"
          >提交
        </el-button>
        <el-button
          type="danger"
         @click="this.close"
          size="small"
          icon="el-icon-close"
          >关闭
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';

export default {
  name: 'administrative_area_maintenance',
  props: {
    propsObj: {},
  },
  data() {
    return {
      form: {},
      selectStartWithCountryApiUrl: '/mdm/mdmRegionController/selectStartWithCountry',
      selectStartWithProvinceApiUrl: '/mdm/mdmRegionController/selectStartWithProvince',
      selectShowObj: {
        countryShow: 'Y',
        townshipShow: 'Y',
      },
      areaObj: {
        areaObjArr: [
          {
            countryCode: '', // 国家编码
            countryCodeOptionsArr: [],
            provinceCode: '', // 省份编码
            provinceCodeOptionsArr: [], // 省份编码选择项
            cityCode: '', // 城市编码
            cityCodeOptionsArr: [], // 城市编码
            districtCode: '', // 区县编码
            districtCodeOptionsArr: [], // 区县编码
            townshipCode: '', // 乡镇编码
            townshipCodeOptionsArr: [], // 乡镇编码
          },

        ],

      },

      params: {
        cityCode: '',
        districtCode: '',
        id: '',
        orgCode: '',
        provinceCode: '',
        regionList: [
          {
            cityCode: '', // 城市编码
            districtCode: '', // 区县编码
            id: '',
            orgCode: '',
            provinceCode: '', // 省份编码
            regionList: [],
            townshipCode: '', // 乡镇编码
          },
        ],
        townshipCode: '',
      },

    };
  },
  created() {
    console.log(158888, this.propsObj);
    this.getRegionShowControl();
    this.getFindOrgRegionList();
  },
  mounted() {

  },
  methods: {

    // 查询组织关联的行政区域列表（不分页）
    // /mdm/mdmOrgRegionController/findOrgRegionList

    getFindOrgRegionList() {
      request.post('/mdm/mdmOrgRegionController/findOrgRegionList', {
        orgCode: this.propsObj.orgCode,
        // orgCode: 'ZZ00000015',
      }).then((res) => {
        // console.log(170000000, res);
        if (res.success) {
          this.areaObj.areaObjArr = res.result.map((a) => ({
            ...a,
            countryCodeOptionsArr: [
              {
                regionName: a.countryName,
                regionCode: a.countryCode,
              },
            ],
            provinceCodeOptionsArr: [
              {
                regionName: a.provinceName,
                regionCode: a.provinceCode,
              },
            ],
            cityCodeOptionsArr: [
              {
                regionName: a.cityName,
                regionCode: a.cityCode,
              },
            ],
            districtCodeOptionsArr: [
              {
                regionName: a.districtName,
                regionCode: a.districtCode,
              },
            ],
            townshipCodeOptionsArr: [
              {
                regionName: a.townshipName,
                regionCode: a.townshipCode,
              },
            ],
          }));
        }
      });
    },

    // 提交表单数据
    // 新增组织和行政区域关联关系
    /// mdm/mdmOrgRegionController/saveOrgRegion

    submitAreaObjArrForm() {
      this.$refs.areaObjArrForm.validate((valid) => {
        // console.log(15999, valid);
        if (valid) {
          // console.log(2199999, this.areaObj);

          request.post('/mdm/mdmOrgRegionController/saveOrgRegion', {
            orgCode: this.propsObj.orgCode,
            // orgCode: 'ZZ00000015',
            regionList: this.areaObj.areaObjArr,
          }).then((res) => {
            // console.log(222888, res);
            if (res.success) {
              this.$message.success(res.message);
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      });
    },

    // 选项赋值

    focusSetOptionArr(parentCode, index, optionArrName) {
      // console.log(171111, parentCode, index, optionArrName, item);
      // if (!(!parentCode && (optionArrName === 'countryCodeOptionsArr' || optionArrName === 'provinceCodeOptionsArr'))) {
      //   return;
      // }
      if (parentCode || optionArrName === 'countryCodeOptionsArr' || (optionArrName === 'provinceCodeOptionsArr' && this.selectShowObj.countryShow === 'N')) {
        this.getArrOptions(parentCode, index, optionArrName);
      }
    },

    // 获取下拉的选项
    getArrOptions(parentCode = '', index, optionArrName) {
      this.areaObj.areaObjArr[index][optionArrName] = [];
      const apiUrl = this.selectShowObj.countryShow === 'Y' ? this.selectStartWithCountryApiUrl : this.selectStartWithProvinceApiUrl;
      request.post(apiUrl, { parentCode }).then((res) => {
        if (res.success) {
          this.areaObj.areaObjArr[index][optionArrName] = res.result;
        }
      });
    },
    // 获取国家 县镇是否显示
    getRegionShowControl() {
      request.get('/mdm/mdmRegionController/getRegionShowControl').then((res) => {
        if (res.success) {
          this.selectShowObj = res.result;
        }
      });
    },
    addAraItemLine() {
      this.areaObj.areaObjArr.push(
        {
          countryCode: '', // 国家编码
          countryCodeOptionsArr: [],
          provinceCode: '', // 省份编码
          provinceCodeOptionsArr: [], // 省份编码选择项
          cityCode: '', // 城市编码
          cityCodeOptionsArr: [], // 城市编码
          districtCode: '', // 区县编码
          districtCodeOptionsArr: [], // 区县编码
          townshipCode: '', // 乡镇编码
          townshipCodeOptionsArr: [], // 乡镇编码
        },
      );
    },
    close() {
      this.$emit('onClose');
    },
    delAreaArrItem(arr, index) {
      arr.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="less">
  .administrative_area_maintenance {
    .administrative_area_maintenance_box{

      .add-line{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .add-line-one{
          span{
            color: #aaa;
          }

        }

      }
      .form-table-box{
        .table-title{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          margin-bottom: 10px;
          &>div{
            flex-shrink: 0;
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #606266;
            font-size: 12px;
            font-weight: 700;
            background-color: #f8f8f9;
            height: 100%;
            border-right: 1px #e8eaec solid;
          }
          .table-title-one{}
          .table-title-two{}
          .table-title-three{}
          .table-title-four{}
          .table-title-five{}
          .table-title-six{
            flex: 1;
          }
        }

        .table-content{

          display: flex;
          align-items: center;
          justify-content: center;
          /*margin: 5px 0;*/
          &>div{
            flex-shrink: 0;
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px transparent solid;

          }
          .table-content-one{}
          .table-content-two{}
          .table-content-three{}
          .table-content-four{}
          .table-content-five{}
          .table-content-six{
            flex: 1;
          }

        }

      }

    }

    .footer-btn {
      position: absolute;
      bottom: 0;
      right: 10px;

      button {
        width: 100px;
      }
    }
  }

</style>
